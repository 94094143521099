@import 'reset.scss';
@import 'fonts.scss';

body {
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  height: 100vh;

  font-family: 'Grafier Regular', -apple-system, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.1px;
  text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
  
  background-color: #FFFF;
  color: #000;

  padding: 0 7.4vw;
  
  @media screen and (max-width: 1280px) {
    font-size: 28px;
    padding: 0 6vw;
  }

  @media screen and (max-width: 720px) {
    height: auto;
    min-height: 100vh;
    font-size: 20px;
    padding: 0 6vw;
  }

  @media screen and (max-width: 414px) {
    font-size: 18px;
    padding: 0 4vw;
  }
}

.Cursor {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: opacity 0.24s ease;
  z-index: 999;
}

header {
  width: 100%;
  height: 140px;
  border-bottom: solid 3px #000;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1080px) {
    height: 120px;
  }
}

.Logo {
  width: 100%;
  height: calc(100% - (2 * 36px));
  background-image: url('../static/logotype.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 720px) {
    height: 100%;
  }
}

main {
  text-align: center;
  width: 100%;
  height: calc(100vh - 140px - 180px - 6px);
  @media screen and (max-width: 1080px) {
    height: calc(100vh - 120px - 180px - 6px);
  }
  @media screen and (max-width: 720px) {
    height: auto;
  }
}

.Content {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 720px) {
    height: auto;
    padding: 38px 0;
  }
}

.ContentTop,
.ContentBottom {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.ContentBottom {
  position: absolute;
  bottom: 30px;
  justify-self: flex-end;
  @media screen and (max-width: 920px) {
    position: initial;
    margin-top: 140px;
    flex-direction: column;
    & div {
      &:first-child {
        margin-bottom: 20px;
      }
      &:last-child {
        box-sizing: border-box;
        padding-right: 50%;
      }
    }
  }
  @media screen and (max-width: 720px) {
    & div {
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.Eagle {
  width: 200px;
  height: 212px;
  background-image: url('../static/eagle.svg');
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  @media screen and (max-width: 1440px) {
    width: 170px;
    height: 180px;
  }
  @media screen and (max-width: 1280px) {
    width: 113px;
    height: 120px;
  }
}

footer {
  box-sizing: border-box;
  border-top: solid 3px #000;
  width: 100%;
  height: 180px;
  padding-top: 38px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 38px;
    & div {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}

.Column {
  flex-basis: 50%;
  text-align: left;
}

ul {
  margin-bottom: 20px;
}

.OpeningHours {
  & li {
    @media screen and (max-width: 414px) {
      margin-bottom: 8px;
    }
    & br {
      display: none;
      @media screen and (max-width: 414px) {
        display: block;
      }
    }
  }
}

.ParagraphLarge {
  font-size: 70px;
  line-height: 1;
  width: 60%;
  @media screen and (max-width: 1720px) {
    width: 80%;
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
  @media screen and (max-width: 1280px) {
    font-size: 60px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 40px;
  }
  @media screen and (max-width: 720px) {
    font-size: 30px;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: #000;
  background-image: linear-gradient(transparent calc(32px - 2px), #000 0px);
  background-repeat: no-repeat;
  background-size: 0 32px;
  transition: background-size 0.3s ease;
  &:hover {
    background-size: 100% 32px;
  }
}

h2 {
  font-weight: normal;
}

::selection {
  color: #FFF;
  background: #000;
  text-shadow: none;
}
